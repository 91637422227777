@import '../../../styles/_variables.scss';

$colorTextPrimary: $black1;
$colorTextSecondary: $black5;
$colorTextError: $red1;
$colorTextWhite: $white;

$letter-spacing: 0.05em;

//Weight
$regular: 400;
$medium: 500;
$semiBold: 600;

$inter-76-92-font-size: 5.067rem;
$inter-76-92-line-height: 1.21;

$inter-60-72-font-size: 4rem;
$inter-60-72-line-height: 1.2;

$inter-48-60-font-size: 3.2rem;
$inter-48-60-line-height: 1.25;

$inter-40-52-font-size: 2.67rem;
$inter-40-52-line-height: 1.3;

$inter-30-40-font-size: 2rem;
$inter-30-40-line-height: 1.33;

$inter-24-32-font-size: 1.6rem;
$inter-24-32-line-height: 1.33;

$inter-20-28-font-size: 1.334rem;
$inter-20-28-line-height: 1.4;

$inter-15-22-font-size: 1rem;
$inter-15-22-line-height: 1.467;

$inter-13-18-font-size: 0.867rem;
$inter-13-18-line-height: 1.385;

$inter_12_16-font-size: 0.8rem;
$inter_12_16-line-height: 1.334;

@mixin font-weight-regular {
  font-weight: $regular;
}

@mixin font-weight-medium {
  font-weight: $medium;
}

@mixin font-weight-semi-bold {
  font-weight: $semiBold;
}

@mixin uppercase {
  text-transform: uppercase;
}

@mixin add-letter-spacing {
  letter-spacing: $letter-spacing;
}

@mixin inter_60_72 {
  font-size: $inter-60-72-font-size;
  line-height: $inter-60-72-line-height;
  @include font-weight-semi-bold;
  color: $colorTextPrimary;
}

@mixin inter_48_60 {
  font-size: $inter-48-60-font-size;
  line-height: $inter-48-60-line-height;
  @include font-weight-semi-bold;
  color: $colorTextPrimary;
}

@mixin inter_40_52 {
  font-size: $inter-40-52-font-size;
  line-height: $inter-40-52-line-height;
  @include font-weight-semi-bold;
  color: $colorTextPrimary;
}

@mixin inter_30_40 {
  font-size: $inter-30-40-font-size;
  line-height: $inter-30-40-line-height;
  @include font-weight-semi-bold;
  color: $colorTextPrimary;
}

@mixin inter_24_32 {
  font-size: $inter-24-32-font-size;
  line-height: $inter-24-32-line-height;
  @include font-weight-semi-bold;
  color: $colorTextPrimary;
}

@mixin inter_20_28 {
  font-size: $inter-20-28-font-size;
  line-height: $inter-20-28-line-height;
  color: $colorTextPrimary;
}

@mixin inter_15_22 {
  font-size: $inter-15-22-font-size;
  line-height: $inter-15-22-line-height;
  color: $colorTextPrimary;
}

@mixin inter_13_18 {
  font-size: $inter-13-18-font-size;
  line-height: $inter-13-18-line-height;
  @include font-weight-medium;
  color: $colorTextPrimary;
}

@mixin inter_12_16 {
  font-size: $inter_12_16-font-size;
  line-height: $inter_12_16-line-height;
  color: $colorTextSecondary;
}
