@import 'fonts';
@import '../styles/variables';
@import '../components/common/typography/typography-variables';

@mixin text-default() {
  font: {
    family: $fontFamilyDefault;
    size: 15px;
  }
  line-height: 25px;
  color: $black1;
}

@mixin strikethrough-text($color) {
  text-decoration: line-through;
  text-decoration-color: $color;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null, $z-index: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
}

@mixin separator-hor() {
  display: block;
  content: '';
  width: 20000em;
  height: 1px;
  margin-left: -10000em;
  background: $blue8;
}

@mixin horizontal-gradient($from, $to, $stop: 100%) {
  background: linear-gradient(to right, $from 0%, $to $stop);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=1);
}

@mixin vertical-gradient($from, $to) {
  background-image: linear-gradient(to bottom, $from, $to);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=0);
}

@mixin transform($transforms) {
  transform: $transforms;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin clamp($clampValue) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $clampValue;
}

@mixin hide-scroll() {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none;
}

@mixin thin-scroll() {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue5;
    border-radius: 20px;
    border: 3px solid $white;
  }
}

@mixin position-modal-form-on-mobile() {
  transform: none;
  max-width: none;
  border-radius: 0;
  @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);
  overflow-x: hidden;
  overflow-y: auto;
}

@mixin fill-last-row-in-grid($selector, $columns) {
  @for $i from ($columns - 2) through 1 {
    #{$selector}:nth-child(#{$columns}n-#{$i}):nth-last-of-type(1) {
      grid-column: span #{$i + 1};
    }
  }
  #{$selector}:nth-child(#{$columns}n-#{$columns - 1}):nth-last-of-type(1) {
    display: none;
  }
}

@mixin black-cover() {
  @include position(absolute, $left: 0px, $top: 0px);
  height: 100%;
  width: 100%;
  opacity: 0.04;
  background-color: $black2;
}

/* 
  Миксин добавляет интерпункты между элементами списка с помощью псевдоэлементов.
  Аргументы:
    $menuSelector - селектор блока меню. Пример селектора для класса: .some-menu
    $menuItemSelector - селектор элемента меню. Пример селектора для класса: .some-menu__item
    $interpunctMargin - расстояние от элемента меню до интерпункта

  Для переопределения стилей для интерпункта (например, цвета и шрифта) в миксин передается контентная часть:
  @include interpunct(...) {
    color: $someColor;
    @include some-typography;
  }
*/
@mixin interpunct($menuSelector, $menuItemSelector, $interpunctMargin: 7px) {
  #{$menuSelector} {
    margin-left: -3 * $interpunctMargin;
    clip-path: inset(0 0 0 3 * $interpunctMargin);
  }

  #{$menuItemSelector} {
    padding-left: $interpunctMargin;
    display: flex;

    &::before {
      flex: 1 0 $interpunctMargin;
      margin-right: $interpunctMargin;
      width: $interpunctMargin;
      text-align: center;
      content: '·';
      @include inter_15_22;
      @include font-weight-medium;
      color: $black5;

      @content;
    }
  }
}

@mixin location-button-default {
  height: 36px;
  border-radius: 4px;
  background-color: $white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin mobile() {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 1280px) {
    @content;
  }
}