@import '../../../styles/_variables.scss';

@mixin link {
  color: $blue2;

  &:focus,
  &:hover {
    color: $blue1;
  }
}

@mixin linkHover {
  color: $blue1;
}

@mixin linkActive {
  color: $black1;
  font-weight: bold;
  &:focus,
  &:hover {
    color: $black1;
  }
}
