@import '../../../styles/variables';
@import '../../../styles/mixins';

$component: '.catalog-product-grid';

#{$component} {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-top: 1px solid $blue8;
}

@include mobile() {
  #{$component} {
    flex-direction: column;
  }
}

@include desktop() {
  #{$component} {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--grid-item-width));

    &_short {
      --grid-item-width: 50%;
    }

    --grid-item-width: 33.33%;

    .catalog-product-grid-item {
      width: 100%;
    }
  }
}

@media (min-width: 1380px) {
  #{$component} {
    &_short {
      --grid-item-width: 33.33%;
    }

    --grid-item-width: 25%;
  }
}

@media (min-width: 1680px) {
  #{$component} {
    &_short {
      --grid-item-width: 25%;
    }

    --grid-item-width: 20%;
  }
}
