@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../common/typography/typography-variables';

$component: '.main-page-products';

#{$component} {
  background-color: $white;

  &__available-count {
    display: none;
  }

  &__filter-mobile {
    border-top: 8px solid $blue8;
    display: flex;
  }
}

@include desktop() {
  #{$component} {
    &__filters {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
    }

    &__available-count {
      display: flex;
      @include inter_20_28;
      @include font-weight-medium;
      color: $colorTextSecondary;
      align-items: center;
    }

    &__filter-mobile {
      display: none;
    }

    &__filter-desktop {
      display: flex;
    }

    &__grid {
      --grid-item-width: 20% !important;
    }
  }
}