@import '../../../../../../styles/_variables';
@import '../../../../../common/button/button-variables';
@import '../../../../../common/typography/typography-variables';
@import '../../../../../../styles/_mixins';

$gap: 12px;

.catalog-filters-mobile {
  $self: &;
  padding: 16px 20px;
  display: flex;
  flex-wrap: nowrap;
  gap: $gap;
  background-color: $white;

  &__modal {
    padding: 12px 20px;
    @include position-modal-form-on-mobile();
  }

  &__configuration-btn {
    flex: 1 0 0;
    width: calc(50% - #{$gap} / 2);
    background-color: $blue8;
    height: 60px;
    border-radius: $button-border-radius;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 11px 16px;
    @include inter_15_22;
    @include font-weight-regular;
    position: relative;

    &:hover,
    &:active {
      background-color: $blue7;

      #{$self}__icon-down {
        --variant: var(--bluish);
      }
    }

    &:focus {
      background-color: $blue7;
    }
  }

  &__preloader {
    @include position(absolute, $right: 16px);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2px;
  }

  &__selected-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    @include font-weight-regular;
    color: $colorTextSecondary;
  }
}

@include desktop() {
  .catalog-filters-mobile {
    display: none;
  }
}
