@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../components/common/typography/typography-variables';
@import '../link/a-variables';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $blue8;
  border-bottom: 1px solid $blue8;
  background-color: $blue8;
}

.pagination__list {
  display: flex;
  justify-content: center;

  li {
    border-left: 1px solid $blue8;

    &:last-child {
      border-right: 1px solid $blue8;
    }
  }
}

.pagination__link,
.pagination__ellipsis,
.pagination__previous,
.pagination__next {
  background-color: $white;
  min-width: 64px;
  @include inter_20_28;
  @include font-weight-regular;
  @include link;
  text-align: center;
  padding: 16px 20px;
  justify-content: center;
  box-sizing: border-box;
}

.pagination__link_active {
  @include linkActive;
  @include inter_20_28;
  @include font-weight-semi-bold;
}

.pagination__previous,
.pagination__next {
  &_disabled,
  &_disabled:focus {
    color: $black6;
    pointer-events: none;
  }
}

.pagination__previous {
  text-align: left;
  border-radius: 0 0 0 4px;
  flex-grow: 1;
  flex-shrink: 1;
}

.pagination__next {
  text-align: right;
  border-radius: 0 0 4px 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.pagination__link,
.pagination__ellipsis {
  display: inline-flex;
}

@include mobile() {
  .pagination__list {
    display: none;
  }

  .pagination__previous,
  .pagination__next {
    @include inter_15_22;
    @include font-weight-regular;
    @include link;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 11px 20px;

    &_disabled {
      color: $black6;
      pointer-events: none;
    }
  }

  .pagination__next {
    border-left: 1px solid $blue8;
  }
}
