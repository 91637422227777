@import '../../../../../common/input/input-variables';
@import '../../../../../../styles/mixins';

$menuWidth: 232px;
$commonMargin: 20px;
$commonPadding: 11px 16px;

.catalog-filters-desktop {
  $self: &;

  &__quick-selection {
    @include font-weight-medium;
    padding: 20px 28px;
    border-bottom: 1px solid $blue8;
  }

  &__form {
    width: $menuWidth;
    padding: 20px 28px;

    &_horizontal {
      width: auto;
      display: flex;
      gap: 20px;
      padding: 0;

      #{$self}__car-brands {
        margin: 0;
      }

      #{$self}__car-models {
        margin: 0;
      }

      #{$self}__car-nodes {
        margin: 0;
      }
    }
  }

  &__car-brands {
    margin-bottom: $commonMargin;
    padding: $commonPadding;
    z-index: 0;
    width: $menuWidth;
  }

  &__car-models {
    z-index: 0;
    margin-bottom: $commonMargin;
    padding: $commonPadding;
    width: $menuWidth;
  }

  &__car-nodes {
    z-index: 0;
    padding: $commonPadding;
    width: $menuWidth;
  }
}

@include mobile() {
  .catalog-filters-desktop {
    display: none;
  }
}
