$body_separator_width: 12px;
$common_padding_content_desktop: 60px;
$common_padding_content_mobile: 20px;

//color palette

//White
$white: white;

//Black
$black1: #161717;
$black1_4: #1E2324;
$black1_6: #22282A;
$black2: #2a3437;
$black3: #5d6162;
$black4: #737474;
$black5: #9d9e9e;
$black6: #d0d1d1;

//Blue
$blue1: #1c6981;
$blue2: #1e8cae;
$blue3: #64a7b9;
$blue4: #92b9c1;
$blue5: #bdd4d8;
$blue6: #dae6e8;
$blue7: #e9f1f2;
$blue8: #f0f5f6;
$blue9: #f8fafa;

//Yellow
$yellow1: #ffc822;
$yellow2: #ffd222;

//Red
$red1: #f64633;

//Временные переменные вариантов цветов (потом нужно заменить)
$linen: #fdf4f2;
$bluishWhite: #f9d3cb;
