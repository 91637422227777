@import '../typography/typography-variables';
@import '../../../styles/variables';

$skeletonBorderRadius: 4px;
$skeletonBackgroundColor: $blue8;

.skeleton {
  border-radius: $skeletonBorderRadius;
  background-color: $skeletonBackgroundColor;
  min-height: 10px;
  min-width: 10px;
  box-sizing: border-box;

  $self: &;

  &_circle {
    border-radius: 50%;
  }

  &_gutter-bottom {
    margin-bottom: 8px;
  }

  &__background {
    background-color: $skeletonBackgroundColor;
    border-radius: $skeletonBorderRadius;
    &_dark {
      background-color: $blue7;
    }
  }

  &_text {
    &Base {
      background-color: transparent;
      --text-skeleton-height: 1rem;
      --text-skeleton-inner-height: 0.9rem;
      --vertical-top: calc((var(--text-skeleton-inner-height) - var(--text-skeleton-height)) / 2);
      height: var(--text-skeleton-inner-height);
      position: relative;

      #{ $self }__background {
        position: absolute;
        left: 0;
        right: 0;
        top: var(--vertical-top);
        height: var(--text-skeleton-height);
      }
    }

    &_h1 {
      --text-skeleton-height: #{$inter-76-92-font-size * 0.87};
      --text-skeleton-inner-height: #{$inter-76-92-font-size * $inter-76-92-line-height};
    }
    &_h2 {
      --text-skeleton-height: #{$inter-60-72-font-size * 0.85};
      --text-skeleton-inner-height: #{$inter-60-72-font-size * $inter-60-72-line-height};
    }
    &_h3 {
      --text-skeleton-height: #{$inter-30-40-font-size * 1.06666};
      --text-skeleton-inner-height: #{$inter-30-40-font-size * $inter-30-40-line-height};
    }
    &_h5 {
      --text-skeleton-height: #{$inter-24-32-font-size * 1.06666};
      --text-skeleton-inner-height: #{$inter-24-32-font-size * $inter-24-32-line-height};
    }
    &_h6 {
      --text-skeleton-height: #{$inter-20-28-font-size * 1.2};
      --text-skeleton-inner-height: #{$inter-20-28-font-size * $inter-20-28-line-height};
    }
    &_body {
      --text-skeleton-height: #{$inter-15-22-font-size * 1.06666};
      --text-skeleton-inner-height: #{$inter-15-22-font-size * $inter-15-22-line-height};
    }
    &_caption {
      --text-skeleton-height: #{$inter_12_16-font-size * 1.09};
      --text-skeleton-inner-height: #{$inter_12_16-font-size * $inter_12_16-line-height};
    }
  }
}
