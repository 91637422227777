$fontFamilyDefault: 'Inter', Helvetica, Arial, sans-serif;

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Inter-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Inter-SemiBold.woff') format('woff');
  font-display: swap;
}
